import React, { useEffect, useMemo, useState } from "react";

import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import "./NotificationPage.scss";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Button } from "@mui/material";
import rooster from "../../../assets/sounds/Rooster-Crow.mp3";
import useSound from "use-sound";
import { useAuth } from "../../../contexts/Auth";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 0,
  margin: "0!important",

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#007BFF",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme === "dark" ? "#5EEECD " : "rgba(0, 123, 255, 0.50) ",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },

      "& .MuiSwitch-thumb": {
        color: theme === "light" ? "#007BFF" : "#3EB489",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#E4E5E8",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    color: "#A6A6A6",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E4E5E8",
    opacity: 1,
  },
}));

const NotificationPage = ({ themeMode, userSettings, setUserSettings }) => {
  const [allMessages, setAllMessages] = useState(false);
  const [tradeAlertsOnly, setTradeAlertsOnly] = useState(false);
  const [noNotifications, setNoNotifications] = useState(false);
  const [sound, setSound] = useState(false);
  const [playRooster] = useSound(rooster);

  const { loading } = useAuth();

  useEffect(() => {
    setSound(userSettings?.notifications?.sound);
    setTradeAlertsOnly(userSettings?.notifications?.mode === 2);
    setAllMessages(userSettings?.notifications?.mode === 1);
    setNoNotifications(userSettings?.notifications?.mode === 0);
  }, [userSettings]);

  const showTest = useMemo(() => {
    if (noNotifications) return false;
    if (tradeAlertsOnly && sound) return true;
    if (allMessages && sound) return true;
  }, [sound, allMessages, tradeAlertsOnly, noNotifications]);
  const setSoundEnabled = (sound) => {
    setUserSettings({
      ...userSettings,
      notifications: {
        ...(userSettings?.notifications || {
          mode: 0,
        }),
        sound,
      },
    });
  };
  const setNotificationMode = (mode) => {
    setUserSettings({
      ...userSettings,
      notifications: {
        sound: mode === 0 ? false : userSettings?.notifications?.sound || false,
        mode,
      },
    });
  };

  const handleNoNotificationsChange = () => {
    setNotificationMode(0);
    setNoNotifications((prev) => {
      if (!prev) {
        setAllMessages(false);
        setTradeAlertsOnly(false);
      }
      return !prev;
    });
  };

  const handleAllMessagesChange = () => {
    setNotificationMode(1);
    setAllMessages((prev) => {
      if (!prev) {
        setTradeAlertsOnly(false);
        setNoNotifications(false);
      }
      return !prev;
    });
  };

  const handleTradeAlertsOnlyChange = () => {
    setNotificationMode(2);
    setTradeAlertsOnly((prev) => {
      if (!prev) {
        setAllMessages(false);
        setNoNotifications(false);
      }
      return !prev;
    });
  };

  return (
    <div className="notification-settings-page">
      <div className="notification-settings-page__header">
        Notification Settings
      </div>

      <div className="notification-settings-page__section">
        <div className="notification-settings-page__section-header">
          Live Chat Notifications
        </div>
        <div className="notification-settings-page__section-content">
          <Tooltip
            placement="right"
            title="Get notified for all messages recieved in the live chat."
          >
            <div className="notification-settings-page__section-content-item">
              <div className="notification-settings-page__section-content-item-label">
                All Messages
              </div>
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={48}
                  height={26}
                />
              ) : (
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={allMessages}
                      onChange={handleAllMessagesChange}
                      sx={{ m: 1 }}
                      theme={themeMode}
                    />
                  }
                />
              )}
            </div>
          </Tooltip>
          <Tooltip
            placement="right"
            title="Get notified for trade alert messages from live chat only."
          >
            <div className="notification-settings-page__section-content-item">
              <div className="notification-settings-page__section-content-item-label">
                Trade Alerts Only
              </div>
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={48}
                  height={26}
                />
              ) : (
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={tradeAlertsOnly}
                      onChange={handleTradeAlertsOnlyChange}
                      sx={{ m: 1 }}
                      theme={themeMode}
                    />
                  }
                />
              )}
            </div>
          </Tooltip>
          <Tooltip
            placement="right"
            title="Disable notification tooltips and sounds."
          >
            <div className="notification-settings-page__section-content-item">
              <div className="notification-settings-page__section-content-item-label">
                No Notifications
              </div>
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={48}
                  height={26}
                />
              ) : (
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={noNotifications}
                      onChange={handleNoNotificationsChange}
                      sx={{ m: 1 }}
                      theme={themeMode}
                    />
                  }
                />
              )}
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="notification-settings-page__section">
        <div className="notification-settings-page__section-header">Sound</div>
        <div className="notification-settings-page__section-content">
          <Tooltip
            placement="right"
            title="Enable desktop sound when recieving notifications."
          >
            <div className="notification-settings-page__section-content-item">
              <div className="notification-settings-page__section-content-item-label">
                Desktop Sound
              </div>
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={48}
                  height={26}
                />
              ) : (
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={sound}
                      onChange={() => {
                        setSound((prev) => {
                          setSoundEnabled(!prev);
                          return !prev;
                        });
                      }}
                      sx={{ m: 1 }}
                      theme={themeMode}
                    />
                  }
                />
              )}
            </div>
          </Tooltip>
          {showTest && (
            <Tooltip
              placement="right"
              title="Click the button to test playing a sound!"
            >
              <div className="notification-settings-page__section-content-item">
                <div className="notification-settings-page__section-content-item-label">
                  Test Sound
                </div>
                {loading ? (
                  <BeautifulSkeleton
                    variant="rectangular"
                    width={48}
                    height={26}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <div className={`update-profile`}>
                        <Button
                          onClick={() => {
                            playRooster();
                          }}
                          className="password-button"
                          variant="contained"
                        >
                          Play Test Sound
                        </Button>
                      </div>
                    }
                  />
                )}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(NotificationPage);
