import React, { useMemo } from "react";
import { randomSelection } from "../helpers";

const possiblePrompts = [
  "Summarize the latest SEC filing",
  "When is {TICKER}'s upcoming earnings?",
  "Recent news related to {TICKER}",
  "Anaylsts' outlook on {TICKER}",
  "Do a technical analysis on {TICKER}",
];

const GetStartedPrompts = ({ isStarted, followUpPrompts, ticker, onSendMessage }) => {
  const suggestionPrompts = useMemo(() => {
    let result = [
      {
        title: "Recommend me an option strategy",
        description: '',
      }
    ];

    const selectedPrompts = randomSelection(possiblePrompts, 2);
    selectedPrompts.forEach((prompt) => {
      result.push({
        title: prompt.replace("{TICKER}", ticker),
        description: '',
      });
    });
    
    if (isStarted) {
      return result;
    } else {
      return followUpPrompts
    }
  }, [ticker, isStarted, followUpPrompts]);

  return (
    <div className="tradegpt-widget__content__get_started">
      <div className="tradegpt-widget__content__get_started__title">
        { isStarted ? "Get Started with these Prompts" : "Suggested Prompts"}
      </div>
      <div className="tradegpt-widget__content__get_started__items">
        {
          suggestionPrompts.map((prompt, index) => (
            <div onClick={() => onSendMessage(prompt.title + " " + prompt.description, true)} key={index} className="tradegpt-widget__content__get_started__item">
              <div className="tradegpt-widget__content__get_started__item__title">
                {prompt.title}
              </div>
              <div className="tradegpt-widget__content__get_started__item__description">
                {prompt.description}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default GetStartedPrompts;
