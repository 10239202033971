import { Tooltip, CircularProgress } from '@mui/material';
import React from 'react';
import ReactMarkdown from "react-markdown";
import _ from 'lodash';
import gfm from "remark-gfm";
import { useMemo } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const renderers = {
  a: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
};

function ChatMessage({ userName, user, message, loading = false, showActions = false, onCopy, onLike, onDislike }) {
  const userAvatar = useMemo(() => {
    if (user?.photoURL) return user.photoURL;
    let hash;
    let gravatarType = "identicon";
    if (!user?.email) {
      hash = "any";
      gravatarType = "mp";
    } else {
      const encoder = new TextEncoder();
      const data = encoder.encode(user.email);
      hash = crypto.subtle.digest("SHA-256", data);
    }
    return `https://www.gravatar.com/avatar/${hash}?s=200&d=${gravatarType}`;
  }, [user?.email, user?.photoURL]);

  return (
    <>
      <div className="w-full text-token-text-primary">
        <div className="justify-center text-base md:gap-6 m-auto">
          <div className="flex flex-1 text-base mx-auto gap-3">
            <div className="flex-shrink-0 flex flex-col relative items-end">
              <div>
                <div className="pt-0.5">
                  <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                    <div className="relative flex">
                      <img
                        src={!message.isBot && !loading ? (userAvatar) : "https://i.pinimg.com/736x/4f/84/7a/4f847ae232438105ff9f35a32a72ecc9.jpg"}
                        alt={message.isBot ? 'Bot' : userName}
                        className="w-6 h-6 rounded-sm"
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex w-full flex-col custom-tradegpt-widget-message-content">
              <div className={`tradegpt-widget-username ${message.isBot ? 'tradegpt-widget-bot-name' : ''}`}>{(!message.isBot) ? userName : "TradeGPT"}</div>
              <div className="flex-col gap-1 md:gap-3">
                <div className="flex flex-grow flex-col max-w-full">
                  <div data-message-author-role="user" className="min-h-[20px] overflow-y-hidden break-words overflow-x-auto flex flex-col gap-3 tradegpt-widget__content__message__text">
                    <div className='markdown-body'>
                      {loading && !message.outputLoading ? (
                        <div style={{ marginLeft: '10px' }} className="flex items-center space-x-2 mt-1">
                          <CircularProgress size={20} color="secondary" />
                          <span className="text-sm text-token-text-secondary">Analyzing ...</span>
                        </div>
                      ) : (
                        <ReactMarkdown components={renderers} children={message.output} remarkPlugins={[gfm]} />
                      )}
                    </div>
                    <div className='tradegpt-widget-message-actions'>
                      {
                        message.isBot && !message.outputLoading && (
                          <>
                            {onCopy &&
                              <Tooltip title="Copy">
                                <button onClick={(e) => onCopy(message.output)} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                  <ContentPasteIcon />
                                </button>
                              </Tooltip>
                            }
                            {onLike && onDislike &&
                              <>
                                {onLike &&
                                  <Tooltip title="Good response">
                                    <button onClick={() => onLike()} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                      <ThumbUpOffAltIcon />
                                    </button>
                                  </Tooltip>
                                }
                                {onDislike &&
                                  <Tooltip title="Bad response">
                                    <button onClick={() => onDislike()} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                      <ThumbDownOffAltIcon />
                                    </button>
                                  </Tooltip>
                                }
                              </>
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatMessage
