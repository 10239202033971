import React, { useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import "./EmeraldRealTimeAlerts.scss";
import { emeraldRealTimeAlertsData } from "./EmeraldRealTimeAlertsData";
import { AddThickIcon, RemoveThickIcon } from "../../common/Icon";

import { connect } from "react-redux";
import TradeAlertsAndSignals from "../TradeAlertsAndSignals";

const EmeraldRealTimeAlerts = ({ themeMode }) => {
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    let duplicatedItems = [...openItems];

    if (duplicatedItems.includes(index)) {
      duplicatedItems = duplicatedItems.filter((obj) => obj !== index);
    } else {
      duplicatedItems.push(index);
    }

    setOpenItems(duplicatedItems);
  };

  const color = useMemo(
    () => (themeMode === "light" ? "#000" : "#fff"),
    [themeMode],
  );

  return (
    <div className="emerald-real-time-alerts">
      <div className="emerald-real-time-alerts__left">
        <div className="emerald-real-time-trading-alerts-component">
          <div className="emerald-real-time-trading-alerts-title">
            Real-Time Trade Alerts on Telegram
          </div>
          <div className="emerald-real-time-trading-alerts-guide">
            <strong style={{ fontWeight: "700!important" }}>
              Emerald Income Alerts Telegram Channel
            </strong>{" "}
            is designed to make trading easier for beginners and more lucrative
            for experienced traders. Whether users want to copy an expert trader
            or build and monetise their existing trades. To access the channel,
            click the link below.
          </div>

          <a
            href="https://t.me/+cwpD3G5-eO5mMDRh"
            target="_blank"
            rel="noreferrer"
            className="platinum-program-telegram-button"
          >
            Join Telegram
          </a>

          <div className="emerald-real-time-trading-alerts-guide">
            Here are 3 easy steps to setup your Telegram and access to Platinum
            Program Telegram Channel to receive real-time trade and movement
            notifications.
          </div>

          {emeraldRealTimeAlertsData.map((section, sectionKey) => (
            <div
              className="emerald-real-time-trading-alerts-section"
              key={sectionKey}
            >
              <div className="emerald-real-time-trading-alerts-section-title">
                {section.title}
              </div>

              {section.items.map((item) => (
                <div
                  className={`emerald-real-time-trading-alerts-section-item ${openItems.includes(item.id) ? "active" : ""}`}
                  onClick={toggleItem.bind(this, item.id)}
                  key={item.id}
                >
                  <div className="emerald-real-time-trading-alerts-section-item-main">
                    {item.title}{" "}
                    {openItems.includes(item.id) ? (
                      <RemoveThickIcon fill={color} />
                    ) : (
                      <AddThickIcon fill={color} />
                    )}
                  </div>
                  <Collapse in={openItems.includes(item.id)}>
                    <div
                      className="emerald-real-time-trading-alerts-section-item-more"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Collapse>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="emerald-real-time-alerts__right">
        <TradeAlertsAndSignals
          themeMode={themeMode}
          url="https://t.me/+cwpD3G5-eO5mMDRh"
        />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(EmeraldRealTimeAlerts);
