import DashboardTableContainer from "./DashboardTableContainer";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TradeAlgoDarkflowCards from "./TradeAlgoDarkflowCards";
import { Helmet } from "react-helmet";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import TopPicksOfTheDay from "./TopPicksOfTheDay/TopPicksOfTheDay";
import DashboardHeader from "./DashboardHeader";
import TradingTools from "./TradingTools/TradingTools";
import LiveTradingSessions from "./LiveTradingSessions/LiveTradingSessions";
// import { useAuth } from "../../contexts/Auth";

export function chunkArray(myArray, chunk_size) {
  var results = [];
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }
  return results;
}

const lookupTableReversed = {
  aht: {
    Intraday: "1",
    Weekly: "2",
    Popular: "3",
  },
};

const HomePage = (props) => {
  const { popLoading, popularStocks, darkflowTable, listen, topPicks } = props;
  let { large, medium, small } = props.darkflowCards;
  // const { showCTABanner, CTABannerLink, loading } = useAuth();
  // state for TABLE controllers
  const [isPopularTab, setIsPopularTab] = useState(false);
  const [up, setUp] = useState(window.location.pathname.includes("Up"));
  const [isShownTradealgoCards, setShownTradealgoCards] = useState(false);
  const currentCards = useMemo(() => {
    const trendingUp = darkflowTable.trending_up || [];
    const trendingDown = darkflowTable.trending_down || [];
    return (up ? trendingUp : trendingDown).slice(0, 10).map((item) => {
      const totalPremium =
        parseInt(item.options?.put_total_prem) +
        parseInt(item.options?.call_total_prem);
      const callPremiumRatio =
        parseInt(item?.options?.call_total_prem) / totalPremium;
      return {
        ...item,
        sentiment:
          (callPremiumRatio ? (callPremiumRatio * 100).toFixed(0) : 0) + "%",
      };
    });
  }, [up, JSON.stringify(darkflowTable)]);
  const tablesLoading = useMemo(() => {
    return isPopularTab ? popLoading : darkflowTable.loading;
  }, [darkflowTable.loading, isPopularTab, popLoading]);

  const allTableTickers = useMemo(() => {
    const allTickers = isPopularTab
      ? popularStocks.map((p) => `${p.symbol}`)
      : currentCards.map((item) => `${item.ticker}`);
    if (allTickers.length) {
      return allTickers;
    } else {
      return [];
    }
  }, [tablesLoading, up, isPopularTab]);
  // state for CARD controllers
  const [value, setValue] = useState("0");

  const topPickTickers = useMemo(() => {
    return topPicks?.map((item) => item.ticker) || [];
  }, [topPicks]);

  useEffect(() => {
    let direction = value === "0" ? "trending_up" : "trending_down";
    const allTickers = large[direction]
      .concat(medium[direction])
      .concat(small[direction])
      .concat(currentCards)
      .map((item) => `${item?.ticker}`);

    if (!!allTickers.length && !!allTableTickers.length) {
      const tickersToListenTo = [
        ...new Set(allTableTickers.concat(topPickTickers).concat(allTickers)),
      ];
      listen(tickersToListenTo, false, false);
    }
    return () => listen([], false, true);
  }, [
    small.loading,
    value,
    allTableTickers,
    topPickTickers,
    isShownTradealgoCards,
  ]);

  const history = useHistory();
  const params = useParams();

  const all = params["0"]?.split("/");
  const allParams = useMemo(() => {
    const [aht, at, t] = all;
    return { aht, at, t };
  }, [history, all]);

  const [darkflowActiveHeaderTab, setDarkflowActiveHeaderTab] = useState(
    allParams.aht ? lookupTableReversed.aht[allParams.aht] : "1",
  );

  const changeDarkflowActiveHeaderTab = (tab) => {
    setDarkflowActiveHeaderTab(tab);
  };

  const toggleShownTradealgoCards = () => {
    setShownTradealgoCards(!isShownTradealgoCards);
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | TradeAlgo </title>
      </Helmet>
      <div className="flex-row">
        <div className="col-12 col-xl-12 px-2">
          <DashboardHeader
            darkflowActiveHeaderTab={darkflowActiveHeaderTab}
            changeDarkflowActiveHeaderTab={changeDarkflowActiveHeaderTab}
          />

          {/* <CustomerFeedback />
          {(showCTABanner && !loading) && <MentorshipPromoBanner link={CTABannerLink} />} */}
          <TopPicksOfTheDay />
          <DashboardTableContainer
            currentCards={currentCards}
            isPopularTab={isPopularTab}
            setIsPopularTab={setIsPopularTab}
            up={up}
            setUp={setUp}
            darkflowActiveHeaderTab={darkflowActiveHeaderTab}
            isShownTradealgoCards={isShownTradealgoCards}
            toggleShownTradealgoCards={toggleShownTradealgoCards}
          />
          {!isPopularTab && (
            <TradeAlgoDarkflowCards
              value={value}
              setValue={setValue}
              isShownTradealgoCards={isShownTradealgoCards}
            />
          )}
          <TradingTools />
          <LiveTradingSessions />
          {/* <CardList /> */}
        </div>
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  subs: state.Socket.channels,
  popLoading: state.popularStocks.loading,
  popularStocks: state.popularStocks.popularStocks,
  darkflowTable: state.darkflowTable,
  darkflowCards: state.darkflow,
  currentSubs: state.dxFeedSocket.currentSubs,
  themeMode: state.themeMode.themeMode,
  topPicks: state.topPicks.topPicks,
});

const dispatchToProps = (dispatch) => ({
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(stateToProps, dispatchToProps)(HomePage);
