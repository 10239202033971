import React from "react";
import IconButton from '@mui/material/IconButton';
import { SendIcon } from './Icons';
import StopIcon from '@mui/icons-material/Stop';

const TradeGPTFooter = ({ text, setText, handleKeyDown, isLoading, onSendMessage, onCancelRequest }) => {
  const handleSendAction = () => {
    if (isLoading) {
      onCancelRequest();
    } else {
      if (text) {
        onSendMessage(text, false);
        setText('');
      }
    }
  }

  return (
    <div className="tradegpt-widget__content__footer">
      <div className="tradegpt-widget__content__footer__input">
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text" placeholder="Ask TradeGPT anything..."
          className="tradegpt-widget-query-input"
          onKeyDown={handleKeyDown}
        />
        <IconButton onClick={handleSendAction} className="tradegpt-widget__content__footer__input__send-icon">
          {
            isLoading ? <StopIcon style={{ color: "#fff" }} /> : <SendIcon />
          }
        </IconButton>

      </div>
      <div className="tradegpt-widget__content__footer__hint">
        This is for informational purposes only. Consult a professional before making investment decisions.
      </div>
      <div className="tradegpt-widget__content__footer__dragger" />
    </div>
  );
}

export default TradeGPTFooter;
