import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import PreviousVideos from "../BasicsTraining/PreviousVideos";
import { connect } from "react-redux";
import { streamsLoad } from "../../appRedux/ducks/pastStreams";
import "./style.scss";

import VerifiedIcon from "@mui/icons-material/Verified";
import profileImg from "../../assets/images/profile.png";

function LiveWalkthrough({ fetchStreams, pastStreams, loading }) {
  const [currentVideo, setCurrentVideo] = useState({
    live: true,
    url: "",
    date: "",
    description: "",
    duration: "",
    link: "",
  });
  const [previousVideos, setPreviousVideos] = useState([]);
  const [tab, setTab] = useState("1");
  useEffect(() => {
    fetchStreams("live-walkthrough");
  }, []);

  useEffect(() => {
    if (pastStreams.length) {
      setPreviousVideos(pastStreams);
    }
  }, [pastStreams]);
  return (
    <div className="container-xl-live">
      <Helmet>
        <title>Live Walkthrough | TradeAlgo</title>
      </Helmet>

      {currentVideo.live ? (
        <div className="live-walkthrough-hero">
          <iframe
            className="live-walkthrough-event-video"
            src="https://vimeo.com/event/2824779/embed/a0a3584673"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="live-walkthrough-video"
          ></iframe>
          <iframe
            src="https://vimeo.com/event/2824779/chat/a0a3584673"
            className="live-walkthrough-event-chat"
            title="live-walkthrough-chat"
          ></iframe>
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: currentVideo.url,
            }}
          />
          <div style={{ position: "relative", top: "5px", left: "29px" }}>
            <div className="row">
              <span
                className="col-12"
                style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
              >
                {currentVideo.duration} | {currentVideo.date} |{" "}
                {tab === "1" ? "Group Training" : ""}
              </span>
            </div>
            <div className="row">
              <span className="col-12" style={{ paddingTop: "10px" }}>
                {currentVideo.description}
              </span>
            </div>
          </div>
          <br />
          <div
            style={{
              width: "99%",
              border: "1px solid #F1F1F1",
              marginLeft: "24px",
            }}
          ></div>
          <br />
        </div>
      )}

      <div className="live-walkthrough-presentor-wrapper">
        <div className="live-walkthrough-presentor">
          <img
            src={profileImg}
            alt="profile"
            className="live-walkthrough-presentor-avatar"
          />
          <div className="live-walkthrough-presentor-detail">
            <div className="live-walkthrough-presentor-name">
              Dane Glisek <VerifiedIcon />
            </div>
            <div className="live-walkthrough-presentor-position">
              TradeAlgo Analyst
            </div>
          </div>
        </div>
      </div>

      <div className="live-walkthrough-event-info">
        <div className="live-walkthrough-event-info-section full-width">
          <div className="live-walkthrough-event-title">
            Platform Walkthrough Sessions
          </div>
          <div className="live-walkthrough-event-description">
            Join us for a platform walkthrough livestream where we&apos;ll be
            taking you on a tour of our latest update. Dane will guide you
            through all the features, functionalities and show you how to get
            the most out of the platform. This is the perfect opportunity to ask
            any questions, get hands-on experience and see how the platform can
            benefit you. Don&apos;t miss out on this exciting chance to learn
            and explore!
          </div>
          <div className="live-walkthrough-event-schedule">
            <div>
              <div className="live-walkthrough-event-schedule-status">
                LIVE Schedule
              </div>
              <div className="live-walkthrough-event-schedule-day">
                Every Monday to Friday
              </div>
              <div className="live-walkthrough-event-schedule-time">
                Time: 7:00pm-8:00pm est
              </div>
            </div>
            <div className="live-walkthrough-event-schedule-reply-msg">
              Replays are available at anytime.
            </div>
          </div>
        </div>
      </div>

      <PreviousVideos
        tab={tab}
        setTab={setTab}
        basics={false}
        loading={loading}
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        videos={previousVideos}
      />
    </div>
  );
}
const stateToProps = (state) => ({
  pastStreams: state.streams.streams,
  loading: state.streams.loading,
});
const dispatchToProps = (dispatch) => ({
  fetchStreams: (endpoint) => dispatch(streamsLoad(endpoint)),
});
export default connect(stateToProps, dispatchToProps)(LiveWalkthrough);
