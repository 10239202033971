import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import PieChartForSentiment from "../../company/PieChartForSentiment";
import TradeAlgoTable from "../../common/Tables/TradeAlgoTable";
import RightArrowLight from "../../../assets/images/right-arrow-light.svg";
import RightArrowDark from "../../../assets/images/right-arrow-dark.svg";
import DiameterImgLight from "../../../assets/images/diameter_180_light.png";
import DiameterImgDark from "../../../assets/images/diameter_180_dark.png";
import NeedleImgLight from "../../../assets/images/needle_light.png";
import NeedleImgDark from "../../../assets/images/needle_dark.png";
import "./TickersInfo.scss";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import { Link } from "react-router-dom";

const TickersInfo = ({ themeMode, activeTicker, loading }) => {
  const history = useHistory();
  const [timeStamp, setTimeStamp] = useState("60min");
  const [sortModel, setSortModel] = useState([
    {
      field: "cp",
      sort: "desc",
    },
    {
      field: "dayVolume",
      sort: "desc", // Default to descending
    },
  ]);
  const keys = Object.keys(activeTicker);
  const isValid = !!keys.length;
  const pieData = useMemo(() => {
    if (isValid) {
      return [
        activeTicker?.aiSentiment[timeStamp]?.bull_score,
        activeTicker?.aiSentiment[timeStamp]?.bear_score,
      ];
    }
    return [0, 0];
  }, [activeTicker, timeStamp, isValid]);
  const percentage = pieData ? Math.max(pieData[0], pieData[0]) : 0;
  const label = percentage > 50 ? "Bullish" : "Bearish";

  useEffect(() => {
    setSortModel([
      {
        field: "cp",
        sort: label === "Bearish" ? "desc" : "asc",
      },
      {
        field: "dayVolume",
        sort: "desc", // Keep dayVolume always sorted in descending order
      },
    ]);
  }, [label, activeTicker]);

  const newsSentiment = useMemo(() => {
    if (typeof activeTicker["newsSentiment"] === "number") {
      return activeTicker?.newsSentiment.toFixed(2) || 0;
    }
    return 0;
  }, [activeTicker, isValid]);

  const rows = useMemo(() => {
    if (isValid) {
      return (
        activeTicker?.optionContracts.filter((item) => !!item.lastPrice) || []
      );
    }
    return [];
  }, [activeTicker, isValid]);
  const columns = useMemo(() => {
    return [
      {
        field: "symbol",
        headerName: "Contract",
        flex: 1.3,
        renderCell: (params) => {
          const date = moment.utc(params.row.expiration).format("MM/DD/YY");
          const optionChainUrl = `/option-chain/${activeTicker?.ticker}?date=${date}`;
          return (
            <Link
              to={optionChainUrl}
              className="ticker-list-top-contracts-item"
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "cp",
        headerName: "C/P",
        flex: 0.7,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item padding-text">
              {params.value === "P" ? "Put" : "Call"}
            </div>
          );
        },
      },
      {
        field: "strike",
        headerName: "Strike",
        flex: 0.8,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item padding-text">
              {params.value}
            </div>
          );
        },
      },
      {
        field: "lastPrice",
        headerName: "Price",
        flex: 0.8,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item padding-text">
              {params.value}
            </div>
          );
        },
      },
      {
        field: "expiration",
        headerName: "Expiry",
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item">
              {moment.utc(params.value).format("MMM Do YY")}
            </div>
          );
        },
      },
      {
        field: "dayVolume",
        headerName: "Volume",
        flex: 0.95,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item padding-text">
              {params.value}
            </div>
          );
        },
      },
      {
        field: "ITM_prob",
        headerName: "ITM%",
        flex: 0.8,
        renderCell: (params) => {
          return (
            <div className="ticker-list-top-contracts-item positive-color padding-text">
              {/* {params.value}% */}
              {(params.value * 100).toFixed(0)}%
            </div>
          );
        },
      },
    ];
  }, [activeTicker]);
  return (
    <div className="tickers-info">
      <div className="tickers-info__header">
        <div className="tickers-info__header_content">
          <div className="tickers-info__header_company_symbol">
            {loading ? <BeautifulSkeleton width={140} /> : activeTicker.ticker}
          </div>
          <div className="tickers-info__header_company_name">
            {loading ? (
              <BeautifulSkeleton width={180} />
            ) : (
              activeTicker.fullCompanyName
            )}
          </div>
        </div>

        <Button
          onClick={() => {
            if (!loading) {
              history.push(`/company/${activeTicker?.ticker}`);
            }
          }}
          variant="contained"
          className="tickers-info__header_button"
        >
          {loading ? (
            <BeautifulSkeleton width={50} />
          ) : (
            `Full ${activeTicker?.ticker} info`
          )}
          <img
            src={themeMode === "light" ? RightArrowLight : RightArrowDark}
            alt="right-arrow"
          />
        </Button>
      </div>

      <div className="tickers-info__content">
        <div className="tickers-info__content_sentiments">
          <div className="tickers-info__content_sentiments_title">
            Sentiments
          </div>
          <div className="tickers-info__content_sentiments_ai_scoring_title">
            A.I. Scoring
          </div>

          <div className="tickers-info-chart-wrapper">
            <div className="tickers-info__content_sentiments_ai_scoring_container">
              <div className="tickers-info__ai_scoring_timestamps">
                <div
                  className={`tickers-info__ai_scoring_timestamps_item ${timeStamp === "60min" ? "active" : ""}`}
                  onClick={() => setTimeStamp("60min")}
                >
                  1 Hour
                </div>
                <div
                  className={`tickers-info__ai_scoring_timestamps_item ${timeStamp === "4h" ? "active" : ""}`}
                  onClick={() => setTimeStamp("4h")}
                >
                  4 Hour
                </div>
                <div
                  className={`tickers-info__ai_scoring_timestamps_item ${timeStamp === "24h" ? "active" : ""}`}
                  onClick={() => setTimeStamp("24h")}
                >
                  1 Day
                </div>
              </div>
              <div className="tickers-info__content_sentiments_ai_scoring_percentage">
                {loading ? (
                  <BeautifulSkeleton
                    sx={{ marginLeft: "25px", marginBottom: "20px" }}
                    variant="circular"
                    width={150}
                    height={150}
                  />
                ) : (
                  <PieChartForSentiment
                    data={pieData}
                    percentage={percentage}
                    label={label}
                  />
                )}
              </div>
            </div>

            <div className="tickers-info__content_sentiments_news_value">
              <div className="tickers-info__news-sentiment-details-content">
                <img
                  src={
                    themeMode === "light" ? DiameterImgLight : DiameterImgDark
                  }
                  alt="Diameter"
                  className="tickers-info__diameter-img"
                />
                <div className="tickers-info__needle-img-wrapper">
                  <img
                    src={themeMode === "light" ? NeedleImgLight : NeedleImgDark}
                    alt="Needle"
                    className="tickers-info__needle-img"
                    style={{ transform: `rotate(${newsSentiment}deg)` }}
                  />
                </div>
              </div>
              <div className="tickers-info__news-sentiment-details-text">
                <div className="tickers-info__news-sentiment-details-title">
                  News Sentiment
                </div>
                <div
                  className={`tickers-info__news-sentiment-details-value tickers-info__news-sentiment-details-value--${newsSentiment > 0 ? "positive" : newsSentiment === 0 ? "neutral" : "negative"}`}
                >
                  {loading ? (
                    <BeautifulSkeleton width={140} />
                  ) : newsSentiment > 0 ? (
                    `+${newsSentiment}`
                  ) : (
                    `${newsSentiment}`
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tickers-info__content_top_contracts">
          <div className="tickers-info__content_top_contracts_title mb-7">
            Popular Contracts
          </div>
          {/* <div className="tickers-info__content_top_contracts_date">
            {rows[0]
              ? moment.utc(rows[0].expiration).format("MMMM Do, YYYY")
              : ""}
          </div> */}
          <div className="tickers-info__content_top_contracts_content">
            <TradeAlgoTable
              rows={rows}
              columns={columns}
              loading={loading}
              sortModel={sortModel}
              setSortModel={setSortModel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TickersInfo);
