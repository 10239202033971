import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { historicalDarkflowLoad } from "../../../appRedux/ducks/historicalDarkflow";
import { decimal } from "../../homePage/TradeAlgoCard";
import { symbolSetter } from "../../../appRedux/ducks/symbol";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import DarkflowTabs from "../Tabs/DarkflowTabs";
import TradeAlgoTable from "./TradeAlgoTable";
import { useAuth } from "../../../contexts/Auth";
import { OpenChannel, SendBirdProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useMedia } from "react-media";
import { ChatSkeleton } from "../Skeletons/ChatSkeleton";
import { useHistory } from "react-router-dom";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.background} !important`,
    color: theme.palette.primary.text,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
  },
  background2: {
    backgroundColor: `${theme.palette.primary.contrastBackground} !important`,
    color: theme.palette.primary.text,
    width: "95%",
    position: "relative",
    left: "29px",
    "& .MuiButton-root": {
      textTransform: "unset !important",
    },
  },
  popupBackground: {
    backgroundColor: `${theme.palette.primary.contrastBackground}!important`,
    color: theme.palette.primary.text,
    boxShadow: theme.palette.primary.popBoxShadow,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));

function NotificationsContainer({
  getHistoricDarkFlow,
  historicalDarkflow,
  setSymbol,
  loadings,
  themeMode,
}) {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const history = useHistory();
  const [cap, setCap] = useState("");
  const [up, setUp] = useState(window.location.pathname.includes("Up"));
  const classes = useStyles();
  const trendingStatus = useMemo(() => {
    return up ? "up" : "down";
  }, [up]);
  const { authUser, userData, loading } = useAuth();
  // const allAuth = useAuth();
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
  const channelUrl =
    process.env.REACT_APP_SENDBIRD_OPEN_CHANNEL_NOTIFICATIONS_URL; // TA: 'sendbird_open_channel_12232_c4f329285fa22fe2280c1a08ebde56b0baeb42c9'
  const nickName = useMemo(() => {
    return userData ? userData?.display_name || "" : "";
  }, [JSON.stringify(userData)]);
  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: "",
  });
  useEffect(() => {
    if (!!userData && !userData.display_name && !loading) {
      history.push("/modify-username");
    }
  }, [userData, loading]);
  useEffect(() => {
    getHistoricDarkFlow(trendingStatus, true);
    let interval = setInterval(() => {
      getHistoricDarkFlow(trendingStatus, true);
      //every 15 min
    }, 900000);
    return () => clearInterval(interval);
  }, [trendingStatus]);
  const sortedData = useMemo(() => {
    let data = [...historicalDarkflow];

    if (cap === "small")
      data = data.filter(
        (item) => Number(item.json_record.market_cap) <= 2000000000,
      );
    else if (cap === "medium")
      data = data.filter(
        (item) =>
          Number(item.json_record.market_cap) > 2000000000 &&
          Number(item.json_record.market_cap) <= 10000000000,
      );
    else if (cap === "large")
      data = data.filter(
        (item) => Number(item.json_record.market_cap) > 10000000000,
      );
    const sortedArray = data.sort((a, b) =>
      moment(b.date_added).diff(a.date_added),
    );
    let flags = [],
      output = [],
      l = 0,
      i;
    for (i = sortedArray.length - 1; i > l; i--) {
      if (flags[sortedArray[i].ticker]) continue;
      flags[sortedArray[i].ticker] = true;
      output.push(sortedArray[i]);
    }
    return output.reverse();
  }, [cap, up, historicalDarkflow]);
  const flex = 1;
  const columns = [
    {
      field: "timeEntered",
      headerName: "TIME ENTERED",
      flex,
      renderCell: (params) => {
        return (
          <div style={{ position: "relative" }}>
            <span className={"item-from"}>
              <div className={clsx(classes.text, "history-item-time")}>
                {moment.parseZone(params.value).format("MMM Do h:mm A")}
              </div>
            </span>
          </div>
        );
      },
    },
    {
      field: "ticker",
      headerName: "TICKER",
      flex,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setSymbol({
                type: "",
                value: params.value,
              });
            }}
            style={{
              position: "relative",
              left: "8px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <Link
              className={classes.text}
              style={{ color: "black" }}
              to={`/company/${params.value}`}
            >
              {params.value}
            </Link>
          </div>
        );
      },
    },
    {
      field: "companyName",
      headerName: "COMPANY NAME",
      flex,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setSymbol({
                type: "",
                value: params.row.ticker,
              });
            }}
            style={{
              position: "relative",
              left: "3px",
              fontWeight: "normal",
              cursor: "pointer",
            }}
          >
            <Link className={classes.text} to={`/company/${params.row.ticker}`}>
              {params.value.slice(0, 30)}
              {params.value.slice(0, 30) === params.value ? "" : "..."}
            </Link>
          </div>
        );
      },
    },
    {
      field: "irregularVol",
      headerName: "IRREGULAR VOLUME",
      flex,
      renderCell: (params) => {
        return (
          <div
            style={{
              height: "27px",
              width: "auto",
              background: up
                ? "rgba(13, 173, 96, 0.05)"
                : themeMode === "dark"
                  ? "rgb(46 4 4)"
                  : "rgb(255, 238, 238",
              color: up
                ? "rgba(13, 173, 96, 1) !important"
                : "rgb(241, 0, 6) !important",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "25px",
              position: "relative",
              padding: "10px",
              left: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: up ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6) ",
              }}
            >
              {parseFloat(Math.round(params.value * 100) / 100)}x
            </span>
          </div>
        );
      },
      sortComparator: (v1, v2) =>
        parseFloat(Math.round(v1 * 100) / 100) -
        parseFloat(Math.round(v2 * 100) / 100),
    },
    {
      field: "dailyPerformance",
      headerName: "PRICE DETECTED",
      flex,
      renderCell: (params) => {
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                position: "relative",
                left: "33px",
              }}
              className={classes.text}
            >
              ${decimal(Number(params.value || 0))}
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2) => Number(v1) - Number(v2),
    },
  ];

  const rows = useMemo(() => {
    return sortedData.length
      ? sortedData.map((item, i) => {
          return {
            id: i + 1,
            idNo: i + 1,
            ticker: item.ticker,
            timeEntered: item.date_added,
            companyName: item.company_name,
            irregularVol: item.json_record.multiplier,
            dailyPerformance: item.json_record.algo_price,
          };
        })
      : [];
  }, [sortedData]);
  const [sortModel, setSortModel] = useState([
    {
      field: "timeEntered",
      sort: "desc",
    },
  ]);
  if (loading) return <ChatSkeleton />;
  return (
    <>
      {!!nickName.length && (
        <div
          className={`sendbird-theme--${themeMode} sendbird-openchannel-app sendbird-openchannel-app-notifications  container`}
        >
          <Helmet>
            <title>Alerts | TradeAlgo</title>
          </Helmet>
          <SendBirdProvider
            appId={appId}
            userId={authUser}
            nickname={nickName}
            stringSet={stringSet}
            theme={themeMode}
          >
            <div className="sendbird-openchannel-app__channel">
              <OpenChannel
                channelUrl={channelUrl}
                disableUserProfile
              ></OpenChannel>
            </div>
          </SendBirdProvider>
        </div>
      )}
      <br />
      <br />

      <div
        style={{ height: isMobile ? "260px" : "132px" }}
        className={classes.background2 + " card"}
      >
        <div className="card-body">
          <div className="row">
            <span
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12 "
              style={{ float: "left", fontWeight: 600, fontSize: "18px" }}
            >
              Get Alerts in Real-time <br />
              <span style={{ fontSize: "13px", fontWeight: "lighter" }}>
                1. Download the telegram chat app on your phone.
                <br />
                <span style={{ position: "relative", top: "-10px" }}>
                  2. Create a free account and login.
                  <br />
                  <span style={{ position: "relative", top: "-10px" }}>
                    3. Join the alerts group by clicking this link{" "}
                    <a
                      href="https://t.me/+86GoP_ctfN1iMTkx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{" "}
                    .
                  </span>
                </span>
              </span>
            </span>
            <span className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12 ">
              <Button
                style={{
                  float: isMobile ? "none" : "right",
                  position: "relative",
                  top: isMobile ? "-7px" : "29px",
                  borderRadius: "12px",
                  background: "#2A79E7",
                }}
                target="_blank"
                onClick={() => window.open("https://t.me/+86GoP_ctfN1iMTkx")}
                variant="contained"
                startIcon={<TelegramIcon />}
              >
                Join Our Private Group
              </Button>
            </span>
          </div>
        </div>
      </div>

      <br />

      <div className="padding-15-row">
        <div className={classes.background + " card"}>
          <div className="card-body">
            <DarkflowTabs
              setCap={setCap}
              history={false}
              notifications={true}
              up={up}
              setUp={setUp}
            />
            <TradeAlgoTable
              rows={rows}
              columns={columns}
              sortModel={sortModel}
              setSortModel={setSortModel}
              loading={loadings}
            />
          </div>
        </div>
      </div>
    </>
  );
}

const stateToProps = (state) => ({
  historicalDarkflow: state.historicalDarkflow.historicalDarkflow,
  loadings: state.historicalDarkflow.loading,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  getHistoricDarkFlow: (trendingStatus, notifications) =>
    dispatch(historicalDarkflowLoad(trendingStatus, notifications)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});

export default connect(stateToProps, dispatchToProps)(NotificationsContainer);
