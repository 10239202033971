import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const STREAMS_LOAD = "STR/LOAD/TOP";
export const STREAMS_LOAD_SUCCESS = "STR/LOAD/TOP/SUCCESS";
export const STREAMS_LOAD_FAILURE = "STR/LOAD/TOP/FAILURE";

// Action Creators
export const streamsLoad = (endpoint) => ({
  type: STREAMS_LOAD,
  endpoint,
});
export const streamsLoadSuccess = (data) => ({
  type: STREAMS_LOAD_SUCCESS,
  data,
});
export const streamsLoadFailure = (error) => ({
  type: STREAMS_LOAD_FAILURE,
  error,
});
// Sagas
function* fetchPastStreams(action) {
  const { endpoint } = action;
  try {
    const final = {
      streams: [],
      loading: true,
    };
    put(streamsLoadSuccess(final));
    const url = `${apiURL}/videos/${endpoint}`;
    const { data } = yield axios.get(url, { withCredentials: true });
    final.streams = data?.data?.data || [];
    final.loading = false;
    yield put(streamsLoadSuccess(final));
  } catch (error) {
    yield put(streamsLoadFailure(error));
  }
}

function* listenFetchStreamsLoad() {
  yield takeEvery(STREAMS_LOAD, fetchPastStreams);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchStreamsLoad)]);
}

const INIT_STATE = {
  streams: [],
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STREAMS_LOAD_SUCCESS:
      return {
        ...state,
        streams: action.data.streams,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
