import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import TickersList from "../TickersList/TickersList";
import TickersInfo from "../TickersInfo/TickersInfo";
import LogoWhite from "../../../assets/images/logo-white.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./TopPicksOfTheDay.scss";
import { connect } from "react-redux";
import { topPicksLoad } from "../../../appRedux/ducks/topPicks";
import { TradeGptWidget } from "tradegpt-widget";
import { useAuth } from "../../../contexts/Auth";
import { findLongestName } from "../DashboardHeader";
import { apiURL, pyApiURL } from "../../../appRedux/actions/helpers";

const TopPicksOfTheDay = ({ loading, topPicks, loadTopPicks }) => {
  const { userData, user } = useAuth();
  const [activeTicker, setActiveTicker] = useState({});
  const [openTradegptWidget, setOpenTradegptWidget] = useState(false);
  const [timeframe, setTimeframe] = useState("24h");

  const toggleTradegptWidget = () => {
    setOpenTradegptWidget(!openTradegptWidget);
  };

  const getTopPicks = () => {
    loadTopPicks(10, timeframe);
  };

  useEffect(() => {
    getTopPicks();
  }, [timeframe]);

  const uid = useMemo(() => user?.uid, [user]);
  const userName = useMemo(() => findLongestName(userData), [userData]);
  return (
    <div className="top-picks-of-the-day">
      <div className="top-picks-of-the-day__title">
        Top Picks Of The Day
        <span className="beta-badge">Beta</span>
      </div>
      <div className="top-picks-of-the-day__content">
        <div className="top-picks-of-the-day__content__body">
          <TickersList
            refetch={getTopPicks}
            loading={loading}
            data={topPicks}
            ticker={activeTicker}
            setTicker={setActiveTicker}
            timeframe={timeframe}
            setTimeframe={setTimeframe}
          />
          <TickersInfo activeTicker={activeTicker} loading={loading} />
        </div>

        <div className="top-picks-of-the-day__content__footer">
          <div className="top-picks-of-the-day__content__footer__splitter" />
          <Button
            variant="contained"
            className="top-picks-of-the-day__content__footer__button"
            onClick={toggleTradegptWidget}
          >
            <img src={LogoWhite} alt="logo" />
            <span className="ai-trade-assitant-button">AI Trade Assistant</span>
            {openTradegptWidget ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
          <div className="top-picks-of-the-day__content__footer__splitter" />
        </div>

        <div
          className={`ai-assistant-widget ${openTradegptWidget ? "active" : ""}`}
        >
          <div className="w-full">
            {!!uid && !!activeTicker?.ticker && (
              <TradeGptWidget
                ticker={activeTicker?.ticker}
                userName={userName}
                user={userData}
                user_id={uid}
                splUrl={apiURL}
                pyApiUrl={pyApiURL}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  topPicks: state.topPicks.topPicks,
  loading: state.topPicks.loading,
});
const dispatchToProps = (dispatch) => ({
  loadTopPicks: (limit, timeframe) => dispatch(topPicksLoad(limit, timeframe)),
});
export default connect(stateToProps, dispatchToProps)(TopPicksOfTheDay);
