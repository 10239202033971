export function extractTitleAndDescription(text) {
  const titleRegex = /\*\*(.*?)\*\*/;
  const titleMatch = text.match(titleRegex);

  if (titleMatch && titleMatch[1]) {
    // Extract title and description
    const title = titleMatch[1];
    const description = text.replace(titleRegex, '').trim();

    return { title, description };
  } else {
    // If no title found, return the whole text as description
    return { title: '', description: text };
  }
}

export function randomSelection(sourceArr, n) {
  let newArr = [];
  if (n >= sourceArr.length) {
    return sourceArr;
  }
  for (let i = 0; i < n; i++) {
    let newElem = sourceArr[Math.floor(Math.random() * sourceArr.length)];
    while (newArr.includes(newElem)) {
    newElem = sourceArr[Math.floor(Math.random() * sourceArr.length)];
    }
    newArr.push(newElem);
  }
  return newArr;
}