import React from "react";

const SuggestedPrompts = ({ suggestionPrompts, onSendMessage }) => {
  return (
    <div className="tradegpt-widget__sidebar__content">
      <div className="tradegpt-widget__sidebar__content__title">
        Suggested Prompts
      </div>

      <div className="tradegpt-widget__sidebar__content__prompts">
        {
          suggestionPrompts.map((prompt, index) => (
            <div onClick={() => onSendMessage(prompt.title + " " + prompt.description, true)} key={index} className="tradegpt-widget__sidebar__content__prompt">
              {prompt.title + " " + prompt.description}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default SuggestedPrompts;
