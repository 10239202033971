import React, { useState } from "react";
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton, OutlinedInput } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from "axios";

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}

const FeedbackModal = ({ open, onClose, isLiked, message, }) => {
  const handleClose = () => {
    onClose()
  }

  const [feedbackText, setFeedbackText] = useState('')
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  
  const closeThankYouModal = () => setShowThankYou(false);

  async function sendResponse() {
    try {
      if (!!message) {
        const payload = {
          message_id: message.chat_id,
          is_thumbs_up: isLiked,
          feedback_text: feedbackText,
        }

        setIsSubmittingFeedback(true);
        await axios.post(`https://dev-presentation.tradealgo.com/tradegpt-post-feedback`, payload, { withCredentials: true,});
        setIsSubmittingFeedback(false);

        if(isLiked) {
          setShowThankYou(true);
        } 
      }
    } catch (error) {
      console.log(`tradegpt-post-feedback ERROR: `, error)
    } finally {
      setIsSubmittingFeedback(false);
      setFeedbackText('')
      handleClose()
    }
  }

  const ThankYouModal = () => (
    <Modal open={showThankYou} onClose={closeThankYouModal}>
      <Box sx={style} className="tradegpt-widget-feedback-modal">
        <div className="tradegpt-widget-feedback-modal-body">
          <div className="tradegpt-widget-feedback-modal-header">
            <div className="tradegpt-widget-feedback-modal-header-left">
              <div>
                <img className="thumb-status-icon" src={isLiked ? 'https://ta-image-hosting.s3.us-east-2.amazonaws.com/thumbs-up.jpg' : 'https://ta-image-hosting.s3.us-east-2.amazonaws.com/thumbs-down.jpg'} alt="thumbs" />
              </div>
              <div className="tracking-tighter">
                Thank you for the feedback
              </div>
            </div>
            <div>
              <IconButton size="small" onClick={closeThankYouModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <div className='text-black font-semibold mt-3 tradegpt-widget-thankyou-message' style={{ "fontSize": "1.25rem", "lineHeight": "1.75rem" }}>
              Having a positive experience with TradeGPT so far?
            </div>
            <div className='text-black mt-4 tradegpt-widget-thankyou-message'>
              Leave us a review on TrustPilot and let others know about your experience.
            </div>
          </div>
          <div className="flex justify-end" style={{ width: '100%' }}>
            <Button
              sx={{
                width: 'auto',
                p: 1,
                paddingTop: '9px',
                borderRadius: '8px',
                border: '1px solid #E4E5E8',
              }}
              onClick={() => {
                window.open('https://www.trustpilot.com/evaluate/tradealgo.com')
                closeThankYouModal();
              }}
              className="feedback-submit-button"
              endIcon={<OpenInNewIcon />}
            >
              Leave Review
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );

  return (
    <>
    <Modal open={open} onClose={handleClose} aria-labelledby="tradegpt-widget-feedback-modal">
      <Box sx={style} className="tradegpt-widget-feedback-modal">
        <div className="tradegpt-widget-feedback-modal-body">
          {/* top section */}
          <div className="tradegpt-widget-feedback-modal-header">
            <div className="tradegpt-widget-feedback-modal-header-left">
              <div>
                <img className="thumb-status-icon" src={isLiked ? 'https://ta-image-hosting.s3.us-east-2.amazonaws.com/thumbs-up.jpg' : 'https://ta-image-hosting.s3.us-east-2.amazonaws.com/thumbs-down.jpg'} alt="thumbs" />
              </div>
              <div className="tracking-tighter">
                Provide Additional Feedback
              </div>
            </div>
            <div>
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {/* text area */}
          <div style={{ width: '100%' }}>
            <OutlinedInput
              multiline
              rows={3}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              className="w-full"
              placeholder={`What do you${isLiked ? '' : ' not'} like about this response?`}
            />
          </div>
          {/* submit button */}
          <div className="flex justify-end" style={{ width: '100%' }}>
            <Button
              disabled={!feedbackText.length || isSubmittingFeedback}
              sx={{
                width: '139px',
                p: 1,
                paddingTop: '9px',
                borderRadius: '8px',
                border: '1px solid #E4E5E8',
              }}
              onClick={sendResponse}
              className="feedback-submit-button"
            >
              Submit Feedback
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
    <ThankYouModal/>
    </>
  )
}
export default FeedbackModal;
