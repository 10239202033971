/* eslint-disable */
import { Helmet } from "react-helmet";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { numberWithCommas } from "../utilities";
import { getSub } from "../TradingView/datafeed";
import { useAuth } from "../../contexts/Auth";
import { getFreeSub } from "../TradingView/FreeTierDatafeed";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";

const CompanyPrice = (props) => {
  const {
    ticker,
    marketStatus,
    price,
    lastPrice,
    pLastPrice,
    setLastPrice,
    securityPrice,
    themeMode,
  } = props;
  const { canSeeInternal } = useAuth();
  useEffect(() => {
    setLastPrice("");
  }, [ticker]);
  useEffect(() => {
    setLastPrice(pLastPrice);
  }, [pLastPrice]);
  const performance = useMemo(() => {
    let lastPriceUsed = {
      "pre-open": parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      open: parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      after: parseFloat(
        price?.close_price || lastPrice || price?.last_price || 0,
      ).toFixed(2),
      closed: parseFloat(
        price?.close_price || lastPrice || price?.last_price || 0,
      ).toFixed(2),
    }[marketStatus];

    let compare = {
      "pre-open": parseFloat(
        price?.pre_open || price?.prev_close_price,
      ).toFixed(2),
      open: parseFloat(price.open_price).toFixed(2),
      after: parseFloat(price.open_price).toFixed(2),
      closed: parseFloat(price.open_price).toFixed(2),
    }[marketStatus];

    return {
      present: lastPriceUsed,
      change: lastPriceUsed - compare,
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
    };
  }, [marketStatus, price, ticker, lastPrice, securityPrice]);
  // Optimized Effect for Subscriptions
  useEffect(() => {
    let currentSub;
    const updatePrice = () => {
      if (canSeeInternal) {
        currentSub = getSub();
      } else {
        currentSub = getFreeSub();
      }
      if (currentSub.isLastBar) {
        setLastPrice(currentSub.close);
      }
    };

    updatePrice(); // Call immediately to update the price

    const interval = setInterval(updatePrice, 1000); // Set interval for repeated calls

    return () => clearInterval(interval); // Clean up on unmount or dependency change
  }, [ticker, canSeeInternal, setLastPrice]); // Removed getSub() from dependency array

  if (performance.present == 0) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>
          {ticker} {!!lastPrice ? `| ${lastPrice}` : ""} | TradeAlgo
        </title>
      </Helmet>
      {performance.present && (
        <div
          style={{
            display: "flex",
            color: themeMode === "light" ? "black" : "white",
          }}
          className="company-price-item"
        >
          {Number(performance.present) == 0 ? (
            <BeautifulSkeleton animation="wave" />
          ) : (
            <>${performance?.present}</>
          )}
          <span className="company-price-usd-symbol">USD</span>
        </div>
      )}
      <div className="company-price-change">
        <div
          className="company-price-percentage-change"
          style={{
            background: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35, 0.1)"
              : "rgba(62, 180, 137, 0.1)",
            color: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35)"
              : "rgba(62, 180, 137)",
          }}
        >
          {String(performance.percent).includes("-") ? "" : "+ "}
          {numberWithCommas(performance.percent ?? "") + "%"}
        </div>
        <span
          className="company-price-change-amount"
          style={{
            color: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35)"
              : "rgba(62, 180, 137)",
          }}
        >
          ({String(performance.percent).includes("-") ? "" : "+ "}
          {performance.change.toFixed(2)})
        </span>
      </div>
    </>
  );
};
const stateToProps = (state) => ({
  price: state.companyData.companyData.secPrice,
  pLastPrice: state.companyData.companyData.lastPrice,
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(CompanyPrice);
