import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import ChatMessage from "../ChatMessage/ChatMessage";
import '@assets/index.css';
import '@assets/darkmode.css';
import '@assets/responsiveness.css';
import '@assets/feedbackModal.css';
import { TradeGPTLogo } from './Icons';
import axios from "axios";
import { extractTitleAndDescription } from "../helpers";
import FeedbackModal from "./FeedbackModal";
import SuggestedPrompts from "./SuggestedPrompts";
import TradeGPTFooter from "./TradeGPTFooter";
import GetStartedPrompts from "./GetStartedPrompts";

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

let abortController;
const App = ({ user, userName, user_id, splUrl, pyApiUrl, ticker }) => {
  const initMessage = [{
    isBot: true,
    output: `Hi ${userName}, I am an AI Assistant. I'm here to help. Ask me questions about anything investing related. Here are a few prompts to help you get started with.`,
    outputLoading: false,
  }]
  const [isLoading, setIsLoading] = useState(false)
  const [session_id,] = useState(uuidv4())
  const [suggestionPrompts, setSuggestionPrompts] = useState([])
  const [messages, setMessages] = useState(initMessage)
  const [text, setText] = useState("")
  const [modalOpen, setModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [actionMessage, setActionMessage] = useState({})
  const [followUpPrompts, setFollowUpPrompts] = useState([])

  useEffect(() => {
    setIsLoading(false)
    onCancelRequest()
    setMessages(initMessage)
  }, [ticker])

  const onSendMessage = (text, suggested) => {
    if (!text) return;

    if (!isLoading) {
      setMessages((prev) => [
        ...prev,
        { isBot: false, output: text, outputLoading: false },
        { isBot: true, output: '', outputLoading: false },
      ]);
      fetchMessage(text, messages.length + 1, suggested); // Pass the index of the bot message
      setText('');
    }
  };

  const onCancelRequest = () => {
    if (!!abortController) {
      abortController.abort();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchPrompts() {
      const { data } = await axios.get(`${splUrl}/tradegpt-recommended-prompts?limit=10&ticker=${ticker}`, { withCredentials: true });

      const prompts = data.map((item) => ({
        ...extractTitleAndDescription(item.query)
      }))
      setSuggestionPrompts(prompts);
    }
    fetchPrompts()
  }, [ticker])

  const fetchMessage = useCallback(async (user_query, botMessageIndex, suggested) => {
    setIsLoading(true);
    let accumulatedMessage = '';
    abortController = new AbortController();
    const { signal } = abortController;
    try {
      const query = {
        user_query,
        session_id,
        chat_id: uuidv4(),
        user_id,
        input_type: suggested ? "recommend" : "user_type",
        ticker
      };
      const response = await fetch(`${pyApiUrl}/v1/assistant`, {
        method: 'POST',
        signal,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(query),
      });

      if (!response.body) {
        throw new Error('No response body');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // Fetch final output after streaming is done
          const finalResponse = await fetch(`${pyApiUrl}/v1/get_stream_output`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(query),
          });

          if (!finalResponse.ok) {
            throw new Error('Failed to fetch final output');
          }

          const finalData = await finalResponse.json();
          
          if (finalData) {
            const followUp = finalData.follow_up ?? [];
            const customFollowUp = followUp.map((item) => ({
              ...item,
              description: item.details
            }));

            setFollowUpPrompts(customFollowUp)
          }

          setMessages((prev) => {
            const updatedMessages = [...prev];
            updatedMessages[botMessageIndex] = {
              ...updatedMessages[botMessageIndex],
              output: accumulatedMessage,
              outputLoading: false,
            };
            return updatedMessages;
          });
          break;
        }
        const chunk = decoder.decode(value);
        accumulatedMessage += chunk;
        setMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[botMessageIndex] = {
            ...updatedMessages[botMessageIndex],
            output: accumulatedMessage,
            outputLoading: true
          };
          return updatedMessages;
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [messages]);

  function scrollIntoView() {
    queueMicrotask(() => {
      // queueMicrotask to allow markdown formatting to kick in so that it can scroll
      const messagesContainer = document.querySelector('.tradegpt-widget__content__messages');
      if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    });
  }

  useEffect(() => {
    scrollIntoView();
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSendMessage(text, false);
    }
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
  };

  const handleLike = (message) => {
    setActionMessage(message)
    setIsLiked(true);
    setModalOpen(true);
  };

  const handleDislike = (message) => {
    setActionMessage(message)
    setIsLiked(false);
    setModalOpen(true);
  };

  const onFeedbackModalClose = () => {
    setActionMessage(null)
    setModalOpen(false);
  }

  return (
    <div className="tradegpt-widget">
      <div className="tradegpt-widget__sidebar">
        <div className="tradegpt-widget__sidebar__header">
          <TradeGPTLogo />
          <div className="tradegpt-widget__sidebar__header__title">AI Trade Assistant</div>
          <div className="tradegpt-widget__sidebar__header__splitter" />
        </div>

        <SuggestedPrompts suggestionPrompts={suggestionPrompts} onSendMessage={onSendMessage} />
      </div>

      <div className="tradegpt-widget__content">
        <div className="tradegpt-widget__content__messages">
          {
            messages.map((message, index) => (
              <div key={index} className={`tradegpt-widget__content__message`}>
                <div className="tradegpt-widget__content__message__text">
                  <ChatMessage
                    key={index}
                    userName={userName}
                    user={user}
                    onCopy={handleCopy}
                    onLike={() => { handleLike(message) }}
                    onDislike={() => { handleDislike(message) }}
                    loading={isLoading && message.isBot && !message.outputLoading && index === messages.length - 1} // Only pass loading to the last bot message when not streaming
                    message={message}
                    showActions={!isLoading && messages.length > 1 && (index + 1) === messages.length && message.isBot}
                  />
                </div>
              </div>
            ))
          }

          {
            isLoading === false && (
              <GetStartedPrompts
                isStarted={messages.length === 1}
                followUpPrompts={ followUpPrompts}
                ticker={ticker}
                onSendMessage={onSendMessage}
              />
            )
          }
        </div>

        <TradeGPTFooter text={text} setText={setText} handleKeyDown={handleKeyDown} isLoading={isLoading} onSendMessage={onSendMessage} onCancelRequest={onCancelRequest} />
      </div>

      <FeedbackModal isLiked={isLiked} open={modalOpen} onClose={onFeedbackModalClose} message={actionMessage} />
    </div>
  )
}
export default App;