import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../contexts/Auth";
import {
  OpenChannel,
  OpenChannelSettings,
  SendBirdProvider,
} from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import "./ChatContainer.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatSkeleton } from "../common/Skeletons/ChatSkeleton";
import moment from "moment";
import { cleanUnreadMessageCount } from "../../appRedux/ducks/socket";
import TradeMessage from "./TradeMessage";
import { tradeMessagesLoad } from "../../appRedux/ducks/tradeChat";
import { updateTradeStatusLoad } from "../../appRedux/ducks/updateTradeStatus";
import AuthorAvatar from "../../assets/images/brian-profile.jpg";
import { useMedia } from "react-media";
import Button from "@mui/material/Button";
import FaqBubble from "../../assets/images/faq-bubble.png";
import Rules from "../../assets/images/rules.png";
import NotificationBell from "../../assets/images/notification-bell.png";
import NotificationBellLight from "../../assets/images/alert-icon-light.png";
import RulesLight from "../../assets/images/notes-icon-light.png";
import FaqBubbleLight from "../../assets/images/faq-icon-light.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DOMPurify from "dompurify";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
function ChatContainer({
  themeMode,
  cleanCount,
  tradeMessagesSocket,
  getTradeMessages,
  tradeMessages,
  updateTradeStatus,
  tab,
  setTab,
}) {
  const [showSettings, setShowSettings] = useState(false);
  const [brianOnly, setBrianOnly] = useState(false);
  const { authUser, userData, loading } = useAuth();
  const history = useHistory();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID; // TA: 'ABE805E6-EEA2-4FAE-9606-0481DD2CFD70'
  const channelUrl = process.env.REACT_APP_SENDBIRD_OPEN_CHANNEL_URL; // TA: 'sendbird_open_channel_12232_c4f329285fa22fe2280c1a08ebde56b0baeb42c9'
  const tradeChannelUrl = process.env.REACT_APP_SENDBIRD_TRADE_CHANNEL_URL; // sendbird_open_channel_12232_084ea4a8b2cbb3ac0ee91e893b8e9f49f5b88660 open trades channel staging
  // "sendbird_open_channel_12232_4c1ed0def604a0d98308868be51a5ef0c7206ee9" prod trades channel

  useEffect(() => {
    if (!!userData && !userData.display_name && !loading) {
      history.push("/settings/profile");
    }
  }, [userData, loading]);

  useEffect(() => {
    return () => {
      cleanCount();
    };
  }, []);
  const nickName = useMemo(() => {
    return userData ? userData?.display_name || "" : "";
  }, [JSON.stringify(userData)]);

  const [stringSet] = useState({
    PLACE_HOLDER__NO_CHANNEL: "",
  });

  const openChannelProps = useMemo(() => {
    const isCommonUser = authUser !== "Py7jBgYDxVTgiP6jVOHb38aDL4F3"; // brian's userId
    const props = {
      ...(isCommonUser && { renderMessageInput: () => <></> }),
    };
    return props;
  }, [authUser, loading]);
  const isAnalyst = useMemo(() => {
    return authUser === "Py7jBgYDxVTgiP6jVOHb38aDL4F3";
  }, [authUser]);
  useEffect(() => {
    getTradeMessages();
  }, []);
  const BottomSection = useMemo(() => {
    return (
      <div style={{ paddingTop: tab === "livestream" ? "" : "9px" }}>
        <div className="openchannel-description">
          <div className="platinum-program-page-live-streams-info-header pl-15">
            <img
              src={AuthorAvatar}
              alt="Brian"
              className="live-streams-author"
            />
            <div className="platinum-program-page-live-streams-author-info ">
              <div className="platinum-program-page-live-streams-author-name">
                Brian Mitchell
              </div>
              <div className="platinum-program-page-live-streams-author-job">
                TradeAlgo Professional Analyst
              </div>
            </div>
          </div>
          <div className="platinum-program-page-live-streams-info-content">
            <div className="platinum-program-page-live-streams-title pl-15">
              Day Trading With Brian
            </div>
            <div className="platinum-program-page-live-streams-description pl-15">
              Join Brian in his Day Trading Sessions as he shares his expertise
              and insights into the world of trading. You&apos;ll receive
              trading alerts from Brian himself live and learn the reasoning
              behind each trade he makes. Join the community as well to interact
              with other like minded traders and Brian himself. Whether
              you&apos;re a new trader just starting out or an experienced one
              looking to secure profitable gains, with Brian&apos;s guidance,
              you&apos;ll be able to use this method to make informed decisions
              and achieve your trading goals.
            </div>
          </div>
        </div>
        <div className="description-block-wrapper">
          <div className="description-blocks w-470 mr-20">
            <span className="title">
              {tab === "real-time-trading-alerts"
                ? "Day Trading Livestream"
                : "Real-Time Alerts"}
            </span>
            <div className="image-title-description">
              <img
                src={
                  themeMode === "light"
                    ? NotificationBell
                    : NotificationBellLight
                }
                className="bell"
              />
              <div className="title-description">
                <span className="inner-title">
                  {tab === "real-time-trading-alerts"
                    ? "LiveStreams"
                    : "Real-Time Alerts & Support"}
                </span>
                <span className="inner-description">
                  {tab === "real-time-trading-alerts"
                    ? "Brian Mitchell answers all your questions live"
                    : "Brian Mitchell provide profitable Trade Alerts and answers question in the live Trade Board and Chat"}
                </span>
              </div>
            </div>
            <Button
              onClick={() =>
                setTab(
                  tab === "real-time-trading-alerts"
                    ? "livestream"
                    : "real-time-trading-alerts",
                )
              }
              className="supports-widget-item-button"
            >
              {tab === "real-time-trading-alerts"
                ? "Livestreams"
                : "Real-Time Alerts"}
            </Button>
          </div>
          <div className="description-blocks w-470">
            <span className="title">Support</span>
            <div className="image-title-description">
              <img
                src={themeMode === "light" ? FaqBubble : FaqBubbleLight}
                className="bell"
              />
              <div className="title-description">
                <span className="inner-title">Freqently Asked Questions</span>
                <span className="inner-description">
                  Get answers to your commonly asked questions
                </span>
              </div>
            </div>
            <Button
              onClick={() => setTab("support")}
              className="supports-widget-item-button"
            >
              FAQs
            </Button>
          </div>
        </div>
      </div>
    );
  }, [isMobile, tab, themeMode]);

  function handleBrianOnly() {
    setBrianOnly(!brianOnly);
    const element = document.querySelector(
      ".sendbird-openchannel-conversation-scroll",
    );
    // Scroll to the bottom
    setTimeout(() => {
      if (element) {
        element.scrollTop = element.scrollHeight;
        document.documentElement.scrollTop = 0;
      }
    }, 100);
  }
  const Component = useMemo(() => {
    return (
      !!nickName.length && (
        <div>
          <Helmet>
            <title> Live Chat | TradeAlgo </title>
          </Helmet>
          <div className="top-block-wrapper">
            <div
              className={`sendbird-theme--${themeMode} sendbird-openchannel-app first-chat-wrapper`}
            >
              <SendBirdProvider
                appId={appId}
                userId={authUser}
                nickname={nickName}
                stringSet={stringSet}
              >
                <div className="sendbird-openchannel-app__channel">
                  {tab === "real-time-trading-alerts" ? (
                    <OpenChannel
                      renderChannelTitle={({ channel }) => {
                        const participants = channel?.participantCount;
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                            className="sendbird-openchannel-conversation-header"
                          >
                            <div className="sendbird-openchannel-conversation-header__left">
                              <div
                                className="sendbird-openchannel-conversation-header__left__cover-image sendbird-avatar"
                                role="button"
                                tabIndex="0"
                                style={{ height: "32px", width: "32px" }}
                              >
                                <div
                                  className="sendbird-avatar-img sendbird-imag-renderer"
                                  style={{
                                    width: " 100%",
                                    minWidth: "32px",
                                    maxWidth: "400px",
                                    height: "32px",
                                  }}
                                >
                                  <div
                                    className="sendbird-image-renderer__image"
                                    style={{
                                      width: "100%",
                                      minWidth: "32px",
                                      maxWidth: "400px",
                                      height: "32px",
                                      position: "absolute",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center center",
                                      backgroundSize: "cover",
                                      backgroundImage:
                                        "url(https://static.sendbird.com/sample/cover/cover_11.jpg)",
                                    }}
                                  ></div>
                                  <img
                                    className="sendbird-image-renderer__hidden-image-loader"
                                    src="https://static.sendbird.com/sample/cover/cover_11.jpg"
                                    alt="channel cover image"
                                  />
                                </div>
                              </div>
                              <span className="sendbird-openchannel-conversation-header__left__title sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1">
                                TradeAlgo
                              </span>
                              <span className="sendbird-openchannel-conversation-header__left__sub-title sendbird-label sendbird-label--body-2 sendbird-label--color-onbackground-2">
                                {participants} participants
                              </span>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              className="sendbird-openchannel-conversation-header__right"
                            >
                              <FormControlLabel
                                control={
                                  <Android12Switch
                                    checked={brianOnly}
                                    onChange={handleBrianOnly}
                                  />
                                }
                                label="Brian Messages Only"
                              />
                            </div>
                          </div>
                        );
                      }}
                      channelUrl={channelUrl}
                      disableUserProfile
                      onChatHeaderActionClick={() => {
                        setShowSettings(true);
                      }}
                      renderCustomMessage={(message) => {
                        const sanitizedMessage = DOMPurify.sanitize(
                          message.message,
                          {
                            ALLOWED_TAGS: ["b"],
                          },
                        );
                        const createMarkup = () => ({
                          __html: sanitizedMessage,
                        });
                        if (
                          message._sender &&
                          (message._sender.userId ===
                            "Py7jBgYDxVTgiP6jVOHb38aDL4F3" ||
                            message._sender.userId ===
                              "5o6pwQ3h37RBXd9bgyJEn94isjf1" ||
                            message._sender.userId ===
                              "1sFupMUajFfYleW6JDBK9CMjg8A3" ||
                            message._sender.userId ===
                              "svr66Mx1ipXlkrUQyhYCGjVMhbm2") &&
                          message.type !== "image/png"
                        ) {
                          const renderUserMessage = () => (
                            <div className="sendbird-msg-hoc sendbird-msg--scroll-ref">
                              <div className="sendbird-openchannel-user-message">
                                <div className="sendbird-openchannel-user-message__left">
                                  <div
                                    className="sendbird-context-menu"
                                    style={{ display: "inline" }}
                                  >
                                    <div
                                      className="sendbird-openchannel-user-message__left__avatar sendbird-avatar"
                                      style={{ height: "28px", width: "28px" }}
                                    >
                                      <div
                                        className="sendbird-avatar-img sendbird-image-renderer"
                                        style={{
                                          width: "100%",
                                          minWidth: "28px",
                                          maxWidth: "400px",
                                          height: "28px",
                                        }}
                                      >
                                        <div
                                          className="sendbird-avatar-img--default"
                                          style={{
                                            width: "28px",
                                            height: "28px",
                                          }}
                                        >
                                          <div
                                            className=" sendbird-icon sendbird-icon-user sendbird-icon-color--content"
                                            style={{
                                              width: "16.1px",
                                              minWidth: "16.1px",
                                              height: "16.1px",
                                              minHeight: "16.1px",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 64 64"
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                            >
                                              <path
                                                className="icon-user_svg__fill"
                                                d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z"
                                                fill="#000"
                                                fillRule="evenodd"
                                              ></path>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="sendbird-openchannel-user-message__right">
                                  <div className="sendbird-openchannel-user-message__right__top">
                                    <span
                                      className={`${message._sender.userId === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" ? "brian" : "ross"} ${message._sender.userId === "svr66Mx1ipXlkrUQyhYCGjVMhbm2" ? "bronco-orange" : ""} sendbird-openchannel-user-message__right__top__sender-name sendbird-label sendbird-label--caption-2 `}
                                    >
                                      {message._sender.nickname}
                                    </span>
                                    <span className="sendbird-openchannel-user-message__right__top__sent-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-3">
                                      {moment(message.createdAt).format("LT")}
                                    </span>
                                  </div>
                                  <div className="sendbird-openchannel-user-message__right__bottom">
                                    <span
                                      dangerouslySetInnerHTML={createMarkup()}
                                      className="sendbird-openchannel-user-message__right__bottom__message sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1"
                                    >
                                      {/* {message.message} */}
                                    </span>
                                  </div>
                                </div>
                                <div className="sendbird-openchannel-user-message__context-menu"></div>
                              </div>
                            </div>
                          );
                          return renderUserMessage;
                        }
                        if (
                          brianOnly &&
                          message._sender.userId !==
                            "Py7jBgYDxVTgiP6jVOHb38aDL4F3"
                        ) {
                          const renderEmptyMessage = () => <div />;
                          return renderEmptyMessage;
                        }
                      }}
                    ></OpenChannel>
                  ) : (
                    <>
                      <iframe
                        className="brian-iframe"
                        src="https://vimeo.com/event/3395808/embed"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title="Livestream Learning"
                        frameBorder="0"
                      />
                    </>
                  )}
                  {!isMobile && BottomSection}
                </div>
                {showSettings && (
                  <div className="sendbird-openchannel-app__settings">
                    <OpenChannelSettings
                      channelUrl={channelUrl}
                      onCloseClick={() => {
                        setShowSettings(false);
                      }}
                    />
                  </div>
                )}
              </SendBirdProvider>
            </div>
            {/*Open Trades Guide*/}
            <div className="second-chat-wrapper">
              <div className="openchannel-app-trades-guide">
                <div className="guide-title">Board Guide</div>
                <div className="trade-colors">
                  <div className="color-wrapper">
                    <div className="green-bar"></div>
                    <div className="green-trade">Open Trade</div>
                  </div>
                  <div className="color-wrapper">
                    <div className="yellow-bar"></div>
                    <div className="yellow-trade">Running Trade</div>
                  </div>
                  <div className="color-wrapper">
                    <div className="red-bar"></div>
                    <div className="red-trade">Closed Trade</div>
                  </div>
                </div>
              </div>
              {/* OPEN TRADES CHAT */}
              <div
                className={`sendbird-theme--${themeMode} sendbird-openchannel-app-trades`}
              >
                <SendBirdProvider
                  appId={appId}
                  userId={authUser}
                  nickname={nickName}
                  stringSet={stringSet}
                >
                  <div
                    className="sendbird-openchannel-app__channel"
                    style={{ overflow: "hidden" }}
                  >
                    <OpenChannel
                      channelUrl={tradeChannelUrl}
                      disableUserProfile
                      {...openChannelProps}
                      renderCustomMessage={(message) => {
                        const renderTradeMessage = () => (
                          <TradeMessage
                            themeMode={themeMode}
                            isAnalyst={isAnalyst}
                            updateStatus={updateTradeStatus}
                            dbMessages={tradeMessages}
                            wsMessages={tradeMessagesSocket}
                            message={message}
                          />
                        );
                        return renderTradeMessage;
                      }}
                    ></OpenChannel>
                  </div>
                </SendBirdProvider>
              </div>
              <div className="rules-section visually-hidden">
                <div className="top-notes">
                  <img
                    src={themeMode === "light" ? Rules : RulesLight}
                    className="wh-27"
                  />
                  <div className="analyst-rules">Rules From Analyst</div>
                </div>
                <span className="desc-style">
                  <li className="bullets-rule">
                    Please have a set position size for trades explicitly
                    mentioned: Superlotto, lotto, and small sized
                  </li>
                  <li className="bullets-rule">
                    No complaining/negativity- 2 strikes and you will be kicked
                    from chat
                  </li>
                  <li className="bullets-rule">
                    When trades reach 20% profit, stop is set to break even
                    unless they are superlotto positions (no stoploss)
                  </li>
                  <li className="bullets-rule">
                    Brian&apos;s stop is automatically 30% from entry for all
                    trades (except for superlottos)
                  </li>
                  <li className="bullets-rule">
                    After a trade is 10% or more from Brian&apos;s entry,{" "}
                    <b>NOT</b> recommended to chase position
                  </li>
                  <li className="bullets-rule">
                    Brian will alert only when there is a good risk to reward,
                    <b>PLEASE DON&apos;T ASK FOR TRADES</b>
                  </li>
                  <li className="bullets-rule">
                    If you ask to look at a stock, Brian will only do so with a
                    premise/reason from a technical analysis standpoint + time
                    frame
                  </li>
                  <li className="bullets-rule">
                    Superlottos are <b>RISKY</b> by nature. They have no
                    stoploss and a majority of our trades lately
                  </li>
                </span>
                <a
                  href="https://docs.google.com/document/d/1NLoLcZm6YorkdCU60N6SEff0PjDSyJXQ_XKIazSEWUw/edit?pli=1"
                  className="full-notes"
                >
                  Click to view all rules
                </a>
              </div>
            </div>
          </div>
          {!!isMobile && (
            <div className="chat-description">{BottomSection}</div>
          )}
        </div>
      )
    );
  }, [
    nickName,
    appId,
    authUser,
    stringSet,
    showSettings,
    themeMode,
    loading,
    openChannelProps,
    tradeMessagesSocket,
    isAnalyst,
    tradeMessages,
    tab,
    isMobile,
    brianOnly,
  ]);
  if (loading) return <ChatSkeleton />;
  return Component;
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  change: state.nameChange.change,
  tradeMessagesSocket: state.Socket.tradeMessages,
  tradeMessages: state.tradeChat.tradeMessages,
});

const dispatchToProps = (dispatch) => ({
  cleanCount: () => dispatch(cleanUnreadMessageCount()),
  getTradeMessages: () => dispatch(tradeMessagesLoad()),
  updateTradeStatus: (trade_status, message_id) =>
    dispatch(updateTradeStatusLoad(trade_status, message_id)),
});

export default connect(stateToProps, dispatchToProps)(ChatContainer);
