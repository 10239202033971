import { DataGridPro } from "@mui/x-data-grid-pro";
import { TableSkeleton } from "../Skeletons/TableSkeleton";
import React, { Suspense } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMedia } from "react-media";
import { connect } from "react-redux";
import clsx from "clsx";
import "./TradeAlgoTable.scss";
import useDebounce from "../../OptionScanner/useDebounce";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.text,
    borderBottomColor: "rgb(60, 64, 67)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottomColor: "rgb(60, 64, 67)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MobileTable({ rows = [], columns, loading, sortModel }) {
  if (!rows.length && !loading) {
    return (
      <div
        className={clsx(
          "tradealgo-table-no-data",
          "MuiDataGrid-windowContainer",
        )}
        style={{ top: "56px", overflowY: "hidden" }}
      >
        No Rows
      </div>
    );
  }
  return (
    <TableContainer elevation={0}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          className={"tradealgo-table-background tradealgo-mobile-table"}
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columns.map((col, index) => {
                if (col.hide) return null;
                return (
                  <StyledTableCell
                    className={"tradealgo-table-text"}
                    key={index}
                  >
                    {col.headerName}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...rows]
              ?.sort((a, b) => b[sortModel] - a[sortModel])
              ?.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    {columns?.map((col, j) => {
                      if (col.hide) return null;
                      if (col.renderCell) {
                        let params = {
                          value: row[columns[j]?.field],
                          row,
                        };
                        return (
                          <StyledTableCell key={j} component="th" scope="row">
                            {col?.renderCell(params)}
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell key={index} component="th" scope="row">
                          {typeof row[columns[j].field] === "object"
                            ? ""
                            : row[columns[j].field]}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

const DesktopTable = ({
  rows,
  columns,
  loading,
  sortModel,
  setSortModel,
  tableClassName,
  hasRowClassName,
  rowClassName,
}) => {
  if (loading) {
    return <TableSkeleton />;
  }
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      className={tableClassName}
      initialState={{
        pinnedColumns: {
          left: [columns.length > 0 ? columns[0]?.field || "" : ""],
        },
      }}
      hideFooterPagination
      disableColumnMenu
      sortingOrder={["asc", "desc"]}
      hideFooter
      autoHeight
      throttleRowsMs={2000}
      sortModel={sortModel}
      scrollbarSize={1}
      getRowClassName={hasRowClassName ? rowClassName : ""}
      onSortModelChange={(model) => {
        if (
          model[0]?.field !== sortModel[0]?.field ||
          model[0]?.sort !== sortModel[0]?.sort
        ) {
          setSortModel(model);
        }
      }}
    />
  );
};

const TradeAlgoTable = ({
  tableClassName = "",
  rows,
  columns,
  loading = false,
  sortModel,
  setSortModel,
  hasRowClassName = false,
  rowClassName = "",
}) => {
  const { isMobile } = useMedia({ queries: mediaQuery }); // Custom hook for media queries
  const debouncedRows = useDebounce(rows, 300);
  const debouncedColumns = useDebounce(columns, 300);

  return (
    <Suspense fallback={<TableSkeleton />}>
      {isMobile ? (
        <MobileTable
          sortModel={sortModel[0]?.field}
          loading={loading}
          rows={debouncedRows}
          columns={debouncedColumns}
        />
      ) : (
        <div className={"tradealgo-table-datagrid"}>
          <div style={{ height: "fit-content", width: "100%" }}>
            <DesktopTable
              sortModel={sortModel}
              loading={loading}
              rows={debouncedRows}
              columns={debouncedColumns}
              tableClassName={tableClassName}
              setSortModel={setSortModel}
              hasRowClassName={hasRowClassName}
              rowClassName={rowClassName}
            />
          </div>
        </div>
      )}
    </Suspense>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TradeAlgoTable);
