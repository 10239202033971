import React from "react";

const TradeGPTLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
      <circle cx="30.5" cy="30" r="29.5" stroke="#E4E5E8"/>
      <path d="M44.6727 41.9999C44.537 41.9412 44.3125 41.8492 44.0891 41.7461C42.5544 41.0353 41.0201 40.323 39.4861 39.6093C38.2168 39.0163 36.9535 38.4112 35.6842 37.8171C34.3384 37.1876 32.9902 36.5618 31.6396 35.9396L26.8001 33.6975C25.0676 32.8951 23.3376 32.0923 21.6099 31.2891C21.4718 31.2274 21.3382 31.1574 21.21 31.0797C20.8582 30.858 20.7621 30.5986 20.9362 30.2451C21.3661 29.3728 21.8141 28.5072 22.2464 27.635C22.7267 26.6807 23.1818 25.7209 23.6562 24.7667C24.496 23.0776 25.3366 21.39 26.178 19.7039C27.0122 18.0333 27.8481 16.3641 28.6855 14.6965C29.0625 13.945 29.4444 13.1969 29.8191 12.4455C29.9488 12.1861 30.1337 12.0299 30.464 12.0022C30.8122 11.9789 30.9983 12.1462 31.128 12.3989C31.4811 13.0831 31.8294 13.7692 32.1728 14.4571C32.6844 15.4845 33.1791 16.5197 33.7027 17.5426C33.7872 17.6946 33.8305 17.8632 33.8291 18.034C33.8276 18.2048 33.7814 18.3728 33.6943 18.5235C33.1875 19.5077 32.706 20.503 32.21 21.4927C31.287 23.3332 30.3623 25.1734 29.436 27.0132C29.3495 27.1872 29.2679 27.3635 29.1958 27.543C29.0469 27.9287 29.2391 28.3066 29.693 28.5172C31.0308 29.1401 32.3746 29.7541 33.7111 30.3803C35.6037 31.267 37.4903 32.1625 39.3805 33.0503C40.1406 33.4071 40.9008 33.7662 41.6717 34.0998C41.9585 34.2318 42.1819 34.4573 42.2986 34.7327C43.3225 36.7764 44.3417 38.8224 45.356 40.8706C45.4246 40.9972 45.4712 41.1329 45.4941 41.2729C45.553 41.6807 45.2167 42.0055 44.6727 41.9999Z" fill="white"/>
      <path d="M15.5 41.2493C15.5178 41.1495 15.5472 41.0518 15.5877 40.9578C16.5588 39.0042 17.5327 37.0514 18.5094 35.0992C18.8829 34.3478 19.2467 33.5908 19.619 32.8383C19.6797 32.7086 19.7545 32.5849 19.8424 32.4692C20.0621 32.1888 20.3347 32.1123 20.6734 32.2642C21.568 32.6665 22.4543 33.0832 23.3453 33.4933C24.1967 33.8834 25.0518 34.2691 25.9008 34.6626C26.476 34.9308 27.0488 35.2045 27.612 35.4927C28.2125 35.7986 28.2125 36.3793 27.612 36.6753C26.7186 37.1186 25.8107 37.5486 24.9052 37.9742C23.1376 38.8025 21.3695 39.6286 19.601 40.4524C18.5658 40.9356 17.5319 41.4222 16.4919 41.8932C15.9803 42.1315 15.512 41.86 15.5 41.2493Z" fill="white"/>
    </svg>
  )
}

const SendIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M13 1L6.4 7.6M13 1L8.8 13L6.4 7.6M13 1L1 5.2L6.4 7.6" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export {
  TradeGPTLogo,
  SendIcon,
};
